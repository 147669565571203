import React from 'react'
import Icon from '@material-ui/core/Icon'
import { useSelector } from "react-redux";
import { Divider, Box, Typography, makeStyles, Tooltip } from '@material-ui/core';
import { getColorStyle, getTrendIcon, getTrendColor, getPreviousPeriodText  } from '../../../components/common/Common';
import { getMetric } from "../../../store/hub/conversionMetrics";

export default function UserStats ({ userId, metric1IdAndValue, metric2IdAndValue }) {
  
  const classes = useStyles();

  const metric1 = useSelector((state) => getMetric(state.entities.conversionMetrics, metric1IdAndValue.id));
  let metric2Id = null;
  if (metric2IdAndValue) {
    metric2Id = metric2IdAndValue.id;
  }
  const metric2 = useSelector((state) => getMetric(state.entities.conversionMetrics, metric2Id));

  try {
    return (
    
      <>
       
        <Divider style={{ width: "80%", marginLeft: "10%" }} />
        <Box className={classes.userStats}>
  
          {metric1 && (
            
            <Box className={classes.stats} key={`user-metric-${userId}-${metric1.code}`}>
              <Tooltip title={<div style={{ fontSize: '12px' }}>{getPreviousPeriodText(metric1IdAndValue)}</div>}>
                <Icon className={classes.icon} style={getColorStyle(getTrendColor(metric1IdAndValue))}>{getTrendIcon(metric1IdAndValue)}</Icon>
              </Tooltip>
              <Typography variant="caption">{metric1IdAndValue.value}</Typography>
            </Box>
            )}
  
          {metric2 && (
            <Box className={classes.stats} key={`user-metric-${userId}-${metric2.code}`}>
              <Tooltip title={<div style={{ fontSize: '12px' }}>{getPreviousPeriodText(metric2IdAndValue)}</div>}>
                <Icon className={classes.icon} style={getColorStyle(getTrendColor(metric2IdAndValue))}>{getTrendIcon(metric2IdAndValue)}</Icon>
              </Tooltip>
              <Typography variant="caption">{metric2IdAndValue.value}</Typography>
            </Box>
          )}
        </Box>
      </>
    );
  } catch (error) {
    return null;
  }
  
}

const useStyles = makeStyles((theme) => ({
  userStats: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  stats: {
    display: "inherit",
    flexDirection: "column",
    justifyContent: "space-evenly",
    textAlign: "center",
  },
  icon: {
    marginLeft: "auto",
    marginRight: "auto",
  },
}));