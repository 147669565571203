import React from "react";
import logoSmall from "./../../assets/images/logo_small.png";
import LogRocket from "logrocket";
import {
  setSelectedThreadId,
  updateMessageThreadRead,
  updateNewMessageRecipients,
  updateNewMessageUi,
  updateSidebar,
  getFeedThreadByIdFromStore,
} from "./StoreCommon";
import {
  setSelectedFeedThreadIdInStore
} from "./StoreFeedUi";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  Computer,
  Hearing,
  Schedule,
  BusinessCenterOutlined,
  Phone,
  PeopleAltOutlined,
  EmojiFoodBeverageOutlined,
  SchoolOutlined,
  HelpOutlineOutlined,
  EmojiObjectsOutlined,
  WorkOutlined,
  People
} from "@material-ui/icons";
import { Box, Typography } from "@material-ui/core";
import Linkify from "react-linkify/dist/components/Linkify";
import TokenHelper from "../../helpers/TokenHelper";
import { PowerOff } from "@material-ui/icons";

if (window.location.href.includes("app.buzzhubs.com")) {
  console.log("Initialising LogRocket");
  LogRocket.init("joqiid/boomteam", {
    dom: {
      textSanitizer: true,
      inputSanitizer: true,
    },
  });
}

export function logRocketCatchError(error, tags, additionalData) {
  LogRocket.captureMessage(error, {
    tags: tags,
    extra: additionalData,
  });
  console.log(error);
}

export function logRocketIdentifyUser(user) {
  // identify the user logged in to LogRocket (for debugging)
  LogRocket.identify(user.Id, {
    name: user.FirstName + " " + user.LastName,
    email: user.UserName,
  });
}

export function formatCurrency(location, style, currency) {
  return new Intl.NumberFormat(location, {
    style: style,
    currency: currency,
  });
}

export async function getUserImageUrl(avatar) {
  if (!avatar) return;
  if (avatar.startsWith("v2")) {
    let avatarId = avatar.split("-")[1];
    return await import(`./../../assets/images/user-images-v2/${avatarId}.png`);      
  }

  return await import(`./../../assets/images/user-images/${avatar}.png`);
  
}

export function convertDateTime(datetime) {
  const dateTime = new Date(datetime);
  const currentDateTime = new Date();
  const time =
    ("0" + dateTime.getHours()).slice(-2) +
    ":" +
    ("0" + dateTime.getMinutes()).slice(-2);
  if (
    currentDateTime.getDate() === dateTime.getDate() &&
    currentDateTime.getMonth() === dateTime.getMonth() &&
    currentDateTime.getFullYear() === dateTime.getFullYear()
  ) {
    // the dateTime is the same day as currentDateTime so return time from dateTime only.
    return time;
  }

  if (
    currentDateTime.getDate() !== dateTime.getDate() &&
    currentDateTime.getMonth() === dateTime.getMonth() &&
    currentDateTime.getFullYear() === dateTime.getFullYear()
  ) {
    // the dateTime is the within the same month as currentDateTime but not the same
    // date so return string day of week along with time.
    const dayOfWeek = getDayOfWeek(dateTime.getDay());
    return dayOfWeek + " " + time;
  }

  if (
    currentDateTime.getMonth() !== dateTime.getMonth() &&
    currentDateTime.getFullYear() === dateTime.getFullYear()
  ) {
    // the dateTime is within the same year as currentDateTime but not within the same
    // month - so return date - month - time
    return (
      dateTime.getDate() +
      " " +
      getMonthInString(dateTime.getMonth()) +
      " " +
      time
    );
  }

  if (currentDateTime.getFullYear() !== dateTime.getFullYear()) {
    // the dateTime is not within the same year as the currentDateTime so return
    // the date / month and year.
    return dateTime.toLocaleDateString();
  }
}

function getDayOfWeek(dayIndex) {
  var weekday = new Array(7);
  weekday[1] = "Mon";
  weekday[2] = "Tue";
  weekday[3] = "Wed";
  weekday[4] = "Thu";
  weekday[5] = "Fri";
  weekday[6] = "Sat";
  weekday[0] = "Sun";

  return weekday[dayIndex];
}

function getMonthInString(monthIndex) {
  let monthStr = new Array(12);
  monthStr[0] = "Jan";
  monthStr[1] = "Feb";
  monthStr[2] = "March";
  monthStr[3] = "April";
  monthStr[4] = "May";
  monthStr[5] = "June";
  monthStr[6] = "July";
  monthStr[7] = "Aug";
  monthStr[8] = "Sep";
  monthStr[9] = "Oct";
  monthStr[10] = "Nov";
  monthStr[11] = "Dec";

  return monthStr[monthIndex];
}

export async function convertUserArrayForStore(userArray) {

  const FullName = userArray.FirstName + " " + userArray.LastName;
  
  let image = userArray.AvatarUrl;
  if (userArray.Avatar) {
    await getUserImageUrl(userArray.Avatar)
      .then((img) => {
        image = img.default;
      })
      .catch((err) => {

        image = logoSmall;
      });
  }

  return {
    Id: userArray.Id,
    FirstName: userArray.FirstName,
    LastName: userArray.LastName,
    FullName: FullName,
    UserName: userArray.UserName,
    ImageSrc: image,
    ColorHex: random_color(),
    PrimaryTeamId: userArray.PrimaryTeamId,
    Banner: userArray.Banner
  };
}

export function convertStatusArrayForStore(user) {
  return {
    Id: user.Id || user.UserId,
    StatusCode: user.StatusCode,
    StatusDateTimeUtc: user.StatusDateTimeUtc,
    StatusDisplayName: user.StatusDisplayName,
    StatusIcon: user.StatusIcon,
    StatusLevel: user.StatusLevel,
    StatusSecondaryDateTimeUtc: user.StatusSecondaryDateTimeUtc,
    StatusSecondarySeconds: user.StatusSecondarySeconds,
    StatusSeconds: user.StatusSeconds,
    LastActionText: user.LastActionText?.replace(
      "#LastActionTime#",
      convertDateTime(user.LastActionDateTimeUtc)
    ),
  };
}

export function sendNotification(title, message) {
  // first check if the user has notifications active
  let options = {
    body: message,
    icon: logoSmall,
  };
  const notification = new Notification(title, options);
  setTimeout(() => {
    notification.close();
  }, 45000);
}

export function createNewMessageNotificationObj(message) {
  let isPrivateMessage = message.UserIds.length > 1 ? false : true;
  const Text = isPrivateMessage
    ? "--USER-- has sent you a new message - " +
      message.LatestMessage.TextContent
    : "--USER-- has added a message to a group you are in - " +
      message.LatestMessage.TextContent;

  return {
    UserId: message.LatestMessage.UserId,
    Id: "MC-" + message.Id,
    Text,
    Read: false,
    DateTimeReceived: convertDateTime(new Date()),
  };
}

export function openMessageThread(threadId) {
  // takes a thread id, updates the store to say the message centre
  // is selected and updates the selected threadId to the given id
  // sets new message to false to close any new message box.
  setSelectedThreadId(threadId);
  updateSidebar({ view: "Inbox" });
  updateMessageThreadRead(threadId, false);
}

export function openFeedThread(threadId) {
  // takes a thread id, updates the store to say the feed is in view
  // is selected and updates the selected threadId to the given id
  const thread = getFeedThreadByIdFromStore(threadId);
  if (thread) {
    setSelectedFeedThreadIdInStore(threadId);
    updateSidebar({ view: "Feed" });
    return true;
  }
  return false;
}

export function messageNewUser(user) {
  newMessage();
  updateNewMessageRecipients([
    {
      Id: user.Id,
      Name: user.FullName,
      Type: "Users",
    },
  ]);
}

export function messageNewHub(hub) {
  newMessage();
  updateNewMessageRecipients([
    {
      Id: hub.Id,
      Name: hub.Name,
      Type: "Hubs",
    },
  ]);
}

function newMessage() {
  updateSidebar({ view: "Inbox", open: true });
  setSelectedThreadId(null);
  updateNewMessageUi(true);
}

export function random_color() {
  let x = Math.floor(Math.random() * 256);
  let y = 100 + Math.floor(Math.random() * 256);
  let z = 50 + Math.floor(Math.random() * 256);
  let color = "rgb(" + x + "," + y + "," + z + ")";

  return color;
}

export function sortByName(array) {
  let newArray = [...array];
  newArray.sort(function (a, b) {
    if (a.Name < b.Name) {
      return -1;
    }
    if (a.Name > b.Name) {
      return 1;
    }
    return 0;
  });

  return newArray;
}

export function sortByFullName(array) {
  let newArray = [...array];
  newArray.sort(function (a, b) {
    if (a.FullName < b.FullName) {
      return -1;
    }
    if (a.FullName > b.FullName) {
      return 1;
    }
    return 0;
  });

  return newArray;
}

export function yyyymmdd(date) {
  function twoDigit(n) {
    return (n < 10 ? "0" : "") + n;
  }

  if (!date) date = new Date();
  if (date) date = new Date(date);

  return (
    "" +
    date.getFullYear() +
    twoDigit(date.getMonth() + 1) +
    twoDigit(date.getDate())
  );
}

export function messageHub(hub) {
  // We used to determine if there was an exising thread to use.
  // Now we simply open the dialog to create a new thread.
    messageNewHub(hub);
}

export const getStatusIcon = (statusIcon, statusLevel, className) => {
  let icon;
  let color = getStatusIconColor(statusLevel);

  switch (statusIcon) {
    case "prep":
      icon = (
        <EmojiObjectsOutlined className={className} style={{ color: color }} />
      );
      break;
    case "admin":
      icon = <Computer className={className} style={{ color: color }} />;
      break;
    case "call-obs":
      icon = <Hearing className={className} style={{ color: color }} />;
      break;
    case "clock":
      icon = <Schedule className={className} style={{ color: color }} />;
      break;
    case "handshake":
      icon = (
        <BusinessCenterOutlined
          className={className}
          style={{ color: color }}
        />
      );
      break;
    case "headphones":
      icon = <Phone className={className} style={{ color: color }} />;
      break;
    case "meeting":
      icon = (
        <PeopleAltOutlined className={className} style={{ color: color }} />
      );
      break;
    case "on-break":
      icon = (
        <EmojiFoodBeverageOutlined
          className={className}
          style={{ color: color }}
        />
      );
      break;
    case "training":
      icon = <SchoolOutlined className={className} style={{ color: color }} />;
      break;
    case "unplugged":
      icon = <PowerOff className={className} style={{ color: color }} />;
      break;
    case "businessdev":
      icon = <WorkOutlined className={className} style={{ color: color }} />;
      break;
    case "teamsmeetingcall":
      icon = <People className={className} style={{ color: color }} />;
      break;
    default:
      icon = (
        <HelpOutlineOutlined className={className} style={{ color: color }} />
      );
      break;
  }

  return icon;
};

const getStatusIconColor = (statusLevel) => {
  let color;
  switch (statusLevel) {
    case 0:
      color = "#1aa700";
      break;
    case 1:
      color = "#ffa013";
      break;
    case 2:
      color = "rgb(218 19 19)";
      break;
    default:
      color = "#1e3653";
      break;
  }

  return color;
};

export const addMinutes = (date, minutes) => {
  return new Date(date.getTime() + minutes * 60000);
};

export function CreateSnackbar({ text }) {
  const [open, setOpen] = React.useState(true);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={60000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
        {text}
      </Alert>
    </Snackbar>
  );
}

export function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function parseJsonMessageForDisplay(message, caption, largeImage) {
  const componentDecorator = (href, text, key) => (
    <a
      style={{ color: "inherit" }}
      href={href}
      key={key}
      target="_blank"
      rel="noreferrer"
    >
      {text}
    </a>
  );

  if (IsJsonString(message)) {
    return JSON.parse(message).Blocks.map((block) => {
      let BlockValue = block.BlockValue;
      if (BlockValue.includes("--newline--"))
        BlockValue = BlockValue.replace("--newline--", "\n");
      if (caption)
        return (
          <Linkify
            key={`Message-${message.Id}-${Math.random()}`}
            componentDecorator={componentDecorator}
          >
            <Typography variant="caption" style={{ fontSize: 12 }}>
              {BlockValue}
            </Typography>
          </Linkify>
        );
      if (block.BlockType === "Boom")
        return (
          <Linkify
            key={`Message-${message.Id}-${Math.random()}`}
            componentDecorator={componentDecorator}
          >
            <Typography variant="body1">{BlockValue}</Typography>
          </Linkify>
        );
      else if (block.BlockType === "Heading1")
        return (
          <Linkify
            key={`Message-${message.Id}-${Math.random()}`}
            componentDecorator={componentDecorator}
          >
            <Typography variant="h5">{BlockValue}</Typography>
          </Linkify>
        );
      else if (block.BlockType === "Image")
        return (
          <Box
            key={`Message-${message.Id}-${Math.random()}`}
            style={{
              height: "auto",
              width: "auto",
              marginLeft: largeImage ? "-16px" : 0,
              marginRight: largeImage ? "-16px" : 0,
              marginTop: 10,
            }}
          >
            <img
              style={{ height: "100%", width: "100%" }}
              src={block.BlockValue}
              alt={`coverImage-${block.BlockValue}`}
            />
          </Box>
        );
      else if (block.BlockType === "Video")
        return (
          <Box
            key={`Message-${message.Id}-${Math.random()}`}
            style={{
              height: "auto",
              width: "auto",
              marginLeft: largeImage ? "-16px" : 0,
              marginRight: largeImage ? "-16px" : 0,
              marginTop: 10,
            }}
          >
            <video
              style={{ height: "100%", width: "100%" }}
              src={block.BlockValue}
              alt={`coverImage-${block.BlockValue}`}
              type="video/mp4"
              controls
            />
          </Box>
        );
      else
        return (
          <Linkify
            key={`Message-${message.Id}-${Math.random()}`}
            componentDecorator={componentDecorator}
          >
            <Typography variant="body1">{BlockValue}</Typography>
          </Linkify>
        );
    });
  } else {
    return "unable to show message content due to parsing error";
  }
}

export function getUrlExtension(url) {
  // https://stackoverflow.com/questions/6997262/how-to-pull-url-file-extension-out-of-url-string-using-javascript
  return url.split(/[#?]/)[0].split('.').pop().trim();
}

export const removeNewLineFromString = (string) => {
  return string.replace("--newline--", "");
};

export const checkForMessageContent = (message) => {
  let hasContent = false;
  if (IsJsonString(message)) {
    message = JSON.parse(message);
    message.Blocks.forEach((block) => {
      if (block.BlockValue) {
        if (!block.BlockValue.includes("--newline--")) {
          hasContent = true;
          return;
        }
      }
    });
  }
  return hasContent;
};

export function isValidDate(d) {
  return d instanceof Date && !Number.isNaN(d);
}

export const getFutureDateTime = (time) => {
  let now = new Date();
  now.setDate(now.getDate() + 1);
  now.setHours(time);
  now.setMinutes(0);
  now.setMilliseconds(0);
  return now;
};

export function createUuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function createAPILoginToken(user) {
  const jwt = require("njwt");
  const secret = process.env.REACT_APP_SECRET;
  const iss = process.env.REACT_APP_JWT_ISS;
  const aud = process.env.REACT_APP_JWT_AUD;

  const payload = {
    nameid: user.email,
    iss: iss,
    aud: aud,
  };
  const token = jwt.create(payload, secret);
  token.setNotBefore(new Date());
  token.setExpiration(new Date().getTime() + 60 * 1000);
  return token.compact();
}

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export const getDateInFutureInUtc = (minutes) => {
  const date = new Date();
  date.setMinutes(date.getMinutes() + minutes);
  return date;
};

export function removeAuthTokensAndRedirectToLogin() {
  const tokenHelper = new TokenHelper();
  tokenHelper.removeAuthTokens();
  // send user back to login page.
  window.location.href = "/login";
}

export function getColorStyle(color) {
  return  {
    color: color
  };
}

export function getBackgroundColorStyle(color) {
  return  {
    background: color
  };
}

export function isNullOrWhitespace(input) {
  if (typeof input === 'undefined' || input == null) return true;
  return input.replace(/\s/g, '').length < 1;
}

export function getTrendIcon(metricIdAndValue) {
  return metricIdAndValue && metricIdAndValue.trend === "Down" ? "trending_down" : "trending_up";
};

export function getTrendColor(metricIdAndValue) {
  return metricIdAndValue && metricIdAndValue.trend === "Down" ? "red" : "green";
};

export function getPreviousPeriodText(metricIdAndValue) {
  return metricIdAndValue ? metricIdAndValue.previousPeriodText : "No previous period data available";
};