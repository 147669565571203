import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "userConversionData",
  initialState: { users: [] },
  reducers: {
    
    // we do this all in one reducer to avoid state updates
    userConversionDataInitialised: (state, action) => {
      action.payload.data.forEach(userData => {
        
        state.users.push( {id: userData.Id, metrics: []} );

        userData.MetricsData.forEach(metricData => {
          
          let timePeriods = [];
          metricData.TimePeriodData.forEach(o => {
            timePeriods.push( { period: o.Period, value: o.FormattedValue, trend: o.Trend, previousPeriodText: o.PreviousPeriodText });
          });

          const user = state.users.find(o => o.id === userData.Id);
          user.metrics.push( { id: metricData.Id, timePeriods: timePeriods } );
        
        });

      });
    },


    userMetricValuesUpdated: (state, action) => {

      const user = state.users.find(o => o.id == action.payload.data.Id);
      if (!user) return;
      
      action.payload.data.MetricsData.forEach(metricData => {
        const metric = user.metrics.find(o => o.id === metricData.Id);
        if (metric) {
          metricData.TimePeriodData.forEach(o => {
            const timePeriod = metric.timePeriods.find(tp => tp.period === o.Period);
            if (timePeriod) {
              timePeriod.value = o.FormattedValue;
              timePeriod.trend = o.Trend;
              timePeriod.previousPeriodText = o.PreviousPeriodText
            }
          });
        }        
      });

    },

  },
});

export const { userConversionDataInitialised, userMetricValuesUpdated } = slice.actions;
export default slice.reducer;

function getMetricIdAndValue(state, userId, period, index) {
  if (!period) return null;

  const user = state.users.find(user => user.id === userId);
  if (!user) return null;
  
  if (index >= user.metrics.length) return null;
  
  let metricId = null;
  let value = null;
  let trend = null;
  let previousPeriodText = null;

  user.metrics[index].timePeriods.forEach(o => {
    if (o.period === period) {
      metricId = user.metrics[index].id;
      value = o.value;
      trend = o.trend;
      previousPeriodText = o.previousPeriodText;
    }
  });

  if (metricId)  {
    return { id: metricId, value: value, trend: trend, previousPeriodText: previousPeriodText};  
  }
  else {
    return null;
  }

}

export const getMetric1IdAndValue = (state, userId, period) => 
{
  return getMetricIdAndValue(state, userId, period, 0);
}

export const getMetric2IdAndValue = (state, userId, period) => 
{
  return getMetricIdAndValue(state, userId, period, 1);
}
