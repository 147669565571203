import { createSlice } from "@reduxjs/toolkit";


const slice = createSlice({
  name: "hubConversionData",
  initialState: { hubs: [] },
  reducers: {
    
    // we do this all in one reducer to avoid state updates
    hubConversionDataInitialised: (state, action) => {
      action.payload.data.forEach(hubData => {
        
        state.hubs.push( {id: hubData.Id, metrics: []} );

        hubData.MetricsData.forEach(metricData => {
          
          let timePeriods = [];
          metricData.TimePeriodData.forEach(o => {
            timePeriods.push( { period: o.Period, value: o.FormattedValue, trend: o.Trend, previousPeriodText: o.PreviousPeriodText });
          });

          const hub = state.hubs.find(o => o.id == hubData.Id);
          hub.metrics.push( { id: metricData.Id, timePeriods: timePeriods } );
        
        });

      });
    },

    hubMetricValuesUpdated: (state, action) => {

      const hub = state.hubs.find(o => o.id == action.payload.data.Id);
      if (!hub) return;
      
      action.payload.data.MetricsData.forEach(metricData => {
        const metric = hub.metrics.find(o => o.id === metricData.Id);
        if (metric) {
          metricData.TimePeriodData.forEach(o => {
            const timePeriod = metric.timePeriods.find(tp => tp.period === o.Period);
            if (timePeriod) {
              timePeriod.value = o.FormattedValue;
              timePeriod.trend = o.Trend;
              timePeriod.previousPeriodText = o.PreviousPeriodText
            }
          });
        }        
      });

    },

  },
});

export const { hubConversionDataInitialised, hubMetricValuesUpdated } = slice.actions;
export default slice.reducer;

function getMetricIdAndValue(state, hubId, period, index) {
  if (!period) return null;

  const hub = state.hubs.find(hub => hub.id === hubId);
  if (!hub) return null;
  
  if (index >= hub.metrics.length) return null;

  let metricId = null;
  let value = null;
  let trend = null;
  let previousPeriodText = null;

  hub.metrics[index].timePeriods.forEach(o => {
    if (o.period === period) {
      metricId = hub.metrics[index].id;
      value = o.value;
      trend = o.trend;
      previousPeriodText = o.previousPeriodText;
    }
  });

  if (metricId)  {
    return { id: metricId, value: value, trend: trend, previousPeriodText: previousPeriodText};  
  }
  else {
    return null;
  }

}

export const getMetric1IdAndValue = (state, hubId, period) => 
{
  return getMetricIdAndValue(state, hubId, period, 0);
}

export const getMetric2IdAndValue = (state, hubId, period) => 
{
  return getMetricIdAndValue(state, hubId, period, 1);
}
